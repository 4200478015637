@import "common.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";

#b-faq-accordion {
  padding:0;
  // box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075),
  //   0 0.25rem 0.45rem rgba(0, 0, 0, 0.075) !important;
  //   border-radius: 5px;
     margin-top:24px;

.accordion-button {
  position: relative;
  display: flex;
  align-items: center;
  width: 100%;
  padding: $accordion-button-padding-y $accordion-button-padding-x;
  @include font-size($font-size-base);
  color: $accordion-button-color;
  text-align: left; // Reset button style
  //background-color: $accordion-button-bg;
  border: 0;
  font-weight: 500;

  @include border-radius(0);
  overflow-anchor: none;
  @include transition($accordion-transition);

  &:not(.collapsed) {
      background-color: transparent;

    &::after {
      background-image: escape-svg($accordion-button-icon);
      transform: $accordion-icon-transform;
    }
  }

  // Accordion icon
  &::after {
    flex-shrink: 0;
    width: $accordion-icon-width;
    height: $accordion-icon-width;
    margin-left: auto;
    content: "";
    background-image: escape-svg($accordion-button-icon);
    background-repeat: no-repeat;
    background-size: $accordion-icon-width;
    @include transition($accordion-icon-transition);
    color:#000
  }

  &:hover {
    z-index: 2;
  }

  &:focus {
    z-index: 3;
    border-color: transparent;
    outline: 0;
    box-shadow: 0 0 #000;
  }
}

.accordion-header {
  margin-bottom: 0;
  font-weight: 400;
}

.accordion-item {
  //background-color: $accordion-bg;
  text-align: left;

  &:first-of-type {
    @include border-top-radius($accordion-border-radius);

    .accordion-button {
      @include border-top-radius($accordion-inner-border-radius);
    }
  }

  &:not(:first-of-type) {
    border-top: 0;
  }

  // Only set a border-radius on the last item if the accordion is collapsed
  &:last-of-type {
    @include border-bottom-radius($accordion-border-radius);

    .accordion-button {
      &.collapsed {
        @include border-bottom-radius($accordion-inner-border-radius);
      }
    }

    .accordion-collapse {
      @include border-bottom-radius($accordion-border-radius);
    }
  }
}

.accordion-body {
  font-size: 0.9em;
  color: #333;
  padding: $accordion-body-padding-y $accordion-body-padding-x;
}


// Flush accordion items
//
// Remove borders and border-radius to keep accordion items edge-to-edge.

.accordion-flush {
  .accordion-collapse {
    border-width: 0;
  }

  .accordion-item {
    border-right: 0;
    border-left: 0;
    @include border-radius(0);

    border-top: 0; 
    border-bottom: 0;

    .accordion-button {
      @include border-radius(0);
    }
  }
}
}