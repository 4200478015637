@import "common.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-booking-modal {
  width: 100%;
  margin-top: 40px;
  color: #222;
  font-size: 0.85em;
  border-top: 0.25em solid $color-gold-3;
  border-bottom: 0.25em solid $color-gold-3;

  .b-bookin-modal-img-wrapper {
    text-align: center;
    img {
      width: 200px;
      margin-bottom: 2em;
    }
  }

  h4 {
    color: $color-gold-1;
    margin-top: 12px;
    font-size: 1.25em;
  }

  a {
    color: #fff;
    text-decoration: none;
    display: block;
  }

  .b-booking-btn
  {
    text-align: center;
    padding-top:10px;

    a {
      color: #000;
    }
  }

  .b-booking-fee
  {
    margin-top:0px;
    padding:12px;
    background: #fff;

    .b-booking-fee-details
    {
      padding-top:4px;
      font-size:10px;
    }
  }

  .b-booking-fee-logo
  {
    text-align: center;
  }

  .b-square-logo
  {
    padding-bottom:4px;
    height: 24px;
  }

  .b-promo-code-section
  {
    padding:30px 0px;
    text-align: center;
  }

  .b-promo-code-btn:first-of-type
  {
    margin-right: 10px;
  }

  .b-promo-input
  {
    margin-top: 15px;
    margin-bottom:10px;
  }

  .b-info
  {
    margin-top:2px;
    font-size:0.8em;
    padding:0;
    margin: 2px 0px 0px 0px;
    position:absolute;
    left:20px;
  }
}

.b-themed-form
{
  border-color: rgba(183,155,110, 0.5);
    &:focus
    {
        box-shadow:  0 0 0 0.2rem rgba(183,155,110, 0.5); 
        border-color: rgba(183,155,110, 0.5);

    }

    &:active:focus
    {
        box-shadow:  0 0 0 0.2rem rgba(183,155,110, 0.5);
        border-color: rgba(183,155,110, 0.5);

    }
}
