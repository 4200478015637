@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-service-card {
  min-height: 300px;
  border: 0;
  border-radius: 0px;
  font-size: 0.85em;
  margin: 20px 0px 20px 0px;
  flex: 1 1 0px;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075),
    0 0.25rem 0.45rem rgba(0, 0, 0, 0.075) !important;

    display: flex;
    justify-content: space-between;
    flex-direction: column;

  @include media-breakpoint-up(md) {
    margin: 20px 20px 20px 20px;


    &:first-of-type {
      margin: 20px 20px 20px 0px;
    }

    &:last-of-type {
      margin: 20px 0px 20px 20px;
    }
  }

  .b-service-card-img-wrapper {
    display: flex;
    overflow: hidden;
    @include media-breakpoint-down(md) 
    {
        max-height: 250px;      
    }

    img 
    { 
      width: 100%;
      height: 100%;
    }
  }

  .b-service-card-title-wrapper {
    padding: 1em 1em 0.25em 1em;
    h2 {
      font-size: 1.25em;
    }
  }

  .b-service-card-text-wrapper {
    padding: 0em 1em 0.25em 1em;
    flex-grow: 1;

    p {
    }
  }

  .b-service-card-footer {
    padding: 0em 1em 1em 1em;
  }

  .b-wide-btn
  {
    width:400px;
  }
}
