@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-insta-card {
  border: 0;
  border-radius: 0px;
  font-size: 0.85em;
  margin: 20px 2px 20px 2px;
  flex: 0.25 0.25 0px;
  flex-grow: 0.2;
  background: #fff;
  border-radius: 5px;
  overflow: hidden;
  text-align: left;
  box-shadow: 0 0.125rem 0.25rem rgba(0, 0, 0, 0.075),
    0 0.25rem 0.45rem rgba(0, 0, 0, 0.075) !important;
  display: flex;
  justify-content: space-between;
  flex-direction: column;

  &:hover
  {
    box-shadow: 0 0.25rem 0.35rem rgba(0, 0, 0, 0.25),
    0 0.35rem 0.55rem rgba(0, 0, 0, 0.25) !important;
  }

  &:first-of-type {
    margin: 20px 2px 20px 0px;
  }

  &:last-of-type {
    margin: 20px 0px 20px 2px;
  }

  @include media-breakpoint-up(sm) {
    margin: 20px 10px 20px 10px;


    &:first-of-type {
      margin: 20px 10px 20px 0px;
    }

    &:last-of-type {
      margin: 20px 0px 20px 10px;
    }
  }

  @include media-breakpoint-up(lg) {
    margin: 20px 22px 20px 22px;


    &:first-of-type {
      margin: 20px 22px 20px 0px;
    }

    &:last-of-type {
      margin: 20px 0px 20px 22px;
    }
  }

  .b-insta-card-img-wrapper {
    display: flex;
    overflow: hidden;
    max-height: 220px;
    cursor: pointer;
    top: 0;
    left: 0;
    
    img 
    { 
      width: 100%;
      height: 100%;
    }
  }
}