@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-service-cards
{
    display: flex;
    justify-content: space-between;
    flex-direction: row;
}

@include media-breakpoint-down(md) {
    .b-service-cards {
        flex-direction: column;
    }
  }
