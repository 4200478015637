@import "common.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-pricing-table
{
  margin-top:2em;
  font-size: 1.1em;
  text-align: left;

  tr
  {
    border-width: 0;
    border-bottom-width: 0.2em;
    border-color: $color-gold-15;
  }

  td, th
  {
    padding: 24px 8px;
  }

  .b-pricing-table-name
  {
    text-transform: uppercase;
    vertical-align: top;
    padding: 24 8px;
  }

  .b-pricing-table-price
  {
    font-weight: 400;
    color: $color-dark-1;
    width: 120px;
    padding: 24 32px;
    vertical-align: top;
  }

  .b-pricing-table-details
  {
    font-size: 0.85em;
    color: rgb(80, 80, 80);
    vertical-align: top;
    padding: 24 8px;
  }
}