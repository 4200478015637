@import "common.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-footer {
  width: 100%;
  margin-top: 40px;
  background: #000;
  color: #fff;
  font-size: 0.85em;
  border-top: 0.25em solid $color-gold-3;
  padding-bottom: 24px;

  img {
    width: 200px;
  }

  h4 {
    color: $color-gold-1;
    margin-top: 12px;
  }

  a {
    color: #fff;
    text-decoration: none;
    display: block;
  }
}
