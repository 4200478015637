@import "common.scss";

.b-about-section {
    padding-top:64px;
    padding-bottom:64px;
    background: $alt-bg;

  .b-about-section-name {
    text-align: left;

    h1 {
      font-size: 2em;
      font-weight: 400;
      display: inline-block;
      border-bottom: 3px solid $color-gold-1;
    }
  }

  .b-about-img-wrapper
  {
    display: none;
    padding:0px 24px  0px 24px;
    img {
      max-height: 300px;
      border-radius: 5px;
    }
  }

  .b-about-img-wrapper2
  {
    img {
      margin-left:auto;
      margin-right:auto;
    }
    text-align: center;
    margin-left:auto;
    margin-right:auto;
    padding:30px;
  }
}