$color-light-0 : rgb(255, 253, 247);
$color-light-1 : rgb(243,213,211);
$color-light-2 : rgba(135, 119, 133, 1); 
$color-light-3 :rgba(135, 119, 133, 1);

$color-dark-1 : rgb(49, 49, 49);
$color-gold-1 : rgb(183,147,110);
$color-gold-15 : rgba(183,147,110, 0.5);
$color-gold-3 : rgb(183, 129, 110);

$alt-bg: linear-gradient(-67deg,  rgb(200,176,140) 0%, rgb(201,177,141) 40%, rgba(179,152,122) 100%);

