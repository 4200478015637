@import "common.scss";

#b-navbar-dark {
  background-color: #000 !important;
  color: #fff;
  border-bottom: 0.25em solid $color-gold-3;
  background: #000 !important;

  a {
    text-decoration: none;
    &:hover {
      color: #fff !important;
    }
  }

  .nav-link
  {
    color: #bbb !important;
  }
}

.b-logo-icon {
  width: 200px;
}

.b-nav-brand {
  margin-right: 100px;
}

.b-nav-link-light {
  color: #bbb !important;
  padding:24px;
  font-size:1.125rem;

  &:first-of-type {
    padding-left:0px;
  }
}

a.b-nav-link-active
{
    color: #fff !important;
}


