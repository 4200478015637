@import "common.scss";

.b-themed-button
{
    background: linear-gradient(67deg, rgb(183,147,110) 0%, rgb(183,135,110) 100%);
    border: 0;
    box-shadow: 0 !important;

    &:hover, &:focus
    {
        background: linear-gradient(78deg, rgb(183,155,110) 0%, rgb(183,141,110) 100%);
    }

    &:focus
    {
        box-shadow:  0 0 0 0.2rem rgba(183,155,110, 0.5); 
    }

    &:active:focus
    {
        box-shadow:  0 0 0 0.2rem rgba(183,155,110, 0.5);
    }
}

.b-themed-button-dark
{
    color:#222;
    &:hover, &:focus
    {
        color:#444;
    }
}

.b-themed-button-black
{
    background: linear-gradient(67deg, rgb(39, 28, 17) 0%, rgb(24, 13, 2) 100%);
    border: 0;
    box-shadow: 0 !important;

    &:hover, &:focus
    {
        background: linear-gradient(67deg, rgb(51, 38, 25) 0%, rgb(27, 18, 9) 100%);
    }
}

.b-themed-button-darker
{
    background: linear-gradient(67deg, rgb(133, 105, 78) 0%, rgb(121, 90, 73) 100%);
    border: 0;
    box-shadow: 0 !important;

    &:hover, &:focus
    {
        background: linear-gradient(67deg, rgb(116, 91, 67) 0%, rgb(112, 84, 69) 100%);
    }
}

.b-themed-button-light
{
    color:#fff;
    &:hover, &:focus
    {
        color:#eee;
    }
}

.b-themed-button-sm
{
    font-size: 1em;
    padding: 0.2em 0.5em 0.2em 0.5em;
}

.b-themed-button-md
{
    font-size: 1em;
}

.wide-btn
{
    padding-left: 2.5em;
    padding-right: 2.5em;
}

.b-themed-button-lg
{
    font-size: 1.5em;
}