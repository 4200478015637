@import "common.scss";

.b-section {
    padding-top:32px;
    padding-bottom: 12px;

  .b-section-name {
    text-align: left;

    h1 {
      font-size: 2em;
      font-weight: 400;
      display: inline-block;
      border-bottom: 3px solid $color-gold-1;
    }
  }
}
