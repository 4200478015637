@import "common.scss";
@import "~bootstrap/scss/bootstrap-utilities.scss";

.b-main-content-container {
  background: url("../assets/stockbgmain_opt.webp");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: top;
  height: 400px;
  color: #fff;
  text-shadow: 6px 6px 18px #222;
  border-bottom: 3px solid rgba(44, 30, 25, 0.25);
  padding-top: 100px;

  h1,
  p {
    text-shadow: 1px 1px 40px rgb(0 0 0), 1px 1px 30px rgb(0 0 0),
      0 0 7px rgb(0 0 0);
  }

  .b-themed-button {
    box-shadow: 1px 1px 40px rgba(0, 0, 0, 1), 1px 1px 20px rgba(0, 0, 0, 0.4);
  }
}

.b-main-content-conainer-alt
{
  height: 400px !important;
}

@include media-breakpoint-up(md) {
  .b-main-content-container {
    min-height: 600px;
    padding-top: 160px;
  }

  .b-main-content-conainer-alt
  {
    min-height: 400px !important;
    padding-top: 100px;
  }
}
@include media-breakpoint-up(lg) {
  .b-main-content-container {
    min-height: 700px;
    padding-top: 240px;
  }

  .b-main-content-conainer-alt
  {
    min-height: 400px !important;
    padding-top: 100px;
  }
}

@media (min-width: 2000px) {
  .b-main-content-container {
    min-height: 900px;
    padding-top: 440px;

    h1
    {
        font-size: 5em;
    }
  }

  .b-main-content-conainer-alt
  {
    min-height: 400px !important;
    padding-top: 100px;
  }

  
}
